<template>
  <div>
    <h1 class="text-capitalize-first-word">{{ title }}</h1>
    <div v-if="route" class="list-buttons mb-3 pb-3">
      <b-button
        v-t="localeSection + '.new'"
        :to="{
          name: route,
          params: { id: '00000000-0000-0000-0000-000000000000' }
        }"
        variant="outline-primary"
      />
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    localeSection: {
      type: String,
      required: true
    },
    route: {
      type: String,
      default: null
    }
  },
  metaInfo() {
    return {
      title: this.title
    };
  },
  computed: {
    title() {
      return this.$t(this.localeSection + ".header");
    }
  }
};
</script>
